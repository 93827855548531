
import {
  Component,
  Inject,
  InjectReactive,
  Prop,
  Vue,
  Watch
} from 'vue-property-decorator';

import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import {
  ActionableHelperView,
  BankIcon,
  BaseIcon,
  BaseText,
  ButtonPrimary,
  ContextColorProvider,
  HelperView,
  Row
} from '@warrenbrasil/nebraska-web';

import { AccountBalance } from '../AccountBalance';
import { AccountWalletDetails } from './components/AccountWalletDetails';
import { AccountWalletDetailsLoader } from './components/AccountWalletDetailsLoader';
import { SingleInput } from '@/modules/transaction/components/SingleInput';
import { InvestmentContainerTemplate } from '../InvestmentContainerTemplate';
import { InvestmentSection } from '../InvestmentSection';
import { InvestmentTransactionNavbarContainer } from './components/InvestmentTransactionNavbarContainer';
import { BankDetailsModal } from '../BankDetailsModal';
import { PortfolioTermsModal } from '@/modules/common/components/PortfolioTermsModal';
import { ReviewTransactionDetails } from './components/ReviewTransactionDetails';
import { InvestmentTransactionContext } from '../../types';
import { PortfolioModule } from '@/store/modules/PortfolioModule';
import {
  trackGenerateInvoiceEvent,
  trackErrorModalEditadabox,
  trackErrorModalScheduled,
  trackErrorModalUnavailable,
  trackErrorTransaction,
  trackErrorTryAgain,
  trackInputFeedbackNoMoney,
  trackInvest,
  trackLoadPortfolioInvestment,
  trackNoMoneyWarning,
  trackSuggestionChip,
  trackInvestmentSchedule,
  trackErrorClickGoBack,
  trackInputFeedbackMinimumValue
} from './track-events';
import {
  Operation,
  userCanPerform,
  isImpersonate
} from '@/modules/common/services/partner';
import {
  ISuggestedPortfolioTransaction,
  validatePortfolioTransaction
} from '@/modules/wealth/services/investment';
import SuggestedTransactionModal from '@/modules/transaction/views/InvestmentTransaction/components/WealthTransaction/components/SuggestedTransactionModal/SuggestedTransactionModal.vue';
import { modalErrorTypes } from './components/SuggestedTransactionModal/types';
import {
  getPortfolioTerm,
  IPortfolioTerms
} from '@/modules/wealth/services/portfolio-terms';

import { WarrenSession } from '@/modules/common/services/session';
import { StorageKeys } from '@/modules/common/services/local-storage';

@Component({
  components: {
    SuggestedTransactionModal,
    BaseIcon,
    AccountBalance,
    ContextColorProvider,
    AccountWalletDetails,
    ActionableHelperView,
    HelperView,
    SingleInput,
    ButtonPrimary,
    BankIcon,
    BaseText,
    Row,
    AccountWalletDetailsLoader,
    InvestmentTransactionNavbarContainer,
    InvestmentContainerTemplate,
    InvestmentSection,
    BankDetailsModal,
    PortfolioTermsModal,
    ReviewTransactionDetails
  }
})
export default class WealthTransaction extends Vue {
  @Prop({ type: String, required: true })
  private portfolio!: string;

  @InjectReactive()
  private context!: InvestmentTransactionContext;

  @Inject()
  private fetchAccountData!: () => Promise<void>;

  @Inject()
  private processTransaction!: (value: number) => Promise<void>;

  @Inject()
  private refreshBalance!: () => Promise<void>;

  @Inject()
  private resetTransactionState!: () => Promise<void>;

  @Inject()
  private openBankDetailsModal!: () => void;

  @Inject()
  private closeBankDetailsModal!: () => void;

  private NebraskaColors = NebraskaColors;
  private hasInputError = false;
  private isScheduledInvestment = false;
  private isValidationTransactionFetchFailed = false;
  private valueToInvest = 0;
  private errorMessage = '';
  public showRiskValidation = false;
  public showReviewModal = false;
  public showSuggestedTransactionModal = false;
  public modalErrorType: modalErrorTypes = modalErrorTypes.Unavailable;
  public scheduleMessage = '';
  public loading = false;
  public hasError = false;
  public portfolioTerms: IPortfolioTerms | null = null;

  private async created() {
    await this.fetchAccountData();
    await this.fetchPortfolioTerms();

    if (!this.context.hasMoneyInAccount) {
      this.handleNoMoneyInAccount();
    }
    trackLoadPortfolioInvestment();
  }

  async retry() {
    await this.fetchAccountData();
    await this.fetchPortfolioTerms();
  }

  private handleSuggestionClick(suggestionLabel: string) {
    trackSuggestionChip(suggestionLabel);
  }

  public validateSubmitInvestment() {
    if (!this.canCreateTransactionToWallet()) return;

    this.showReviewModal = true;
  }

  tryAgainSubmitInvestment() {
    trackErrorTryAgain();
    this.submitInvestment();
  }

  submitValidationInvestment() {
    if (this.portfolioTerms?.requestRisksTerm && this.canShowTermsAndRisks) {
      this.showRiskValidation = true;
      return;
    }

    this.submitInvestment();
  }

  private async submitInvestment() {
    this.showRiskValidation = false;
    if (!(await this.canCreateTransactionToSuggestedWallet())) return;
    this.loading = true;
    try {
      await this.processTransaction(this.valueToInvest);
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  private canCreateTransactionToWallet() {
    const totalBalance = this.context.totalBalance.value!;

    const minimumValueToDeposit =
      this.context.portfolioInformation?.minimumValueToDeposit;

    if (
      !this.valueToInvest ||
      (minimumValueToDeposit &&
        this.valueToInvest < minimumValueToDeposit?.value)
    ) {
      this.setInputError(
        `Você precisa inserir um valor maior que ${minimumValueToDeposit?.formatted} para investir`
      );
      trackInputFeedbackMinimumValue(
        this.context.portfolioName,
        this.valueToInvest,
        minimumValueToDeposit?.value || 0
      );

      return false;
    }

    if (this.valueToInvest > totalBalance) {
      this.setInputError(
        'O valor informado é maior que o saldo disponível em conta'
      );
      trackInputFeedbackNoMoney(this.context.portfolioName, this.valueToInvest);

      return false;
    }

    this.resetInputErrors();
    return true;
  }

  private async canCreateTransactionToSuggestedWallet() {
    this.loading = true;
    try {
      const {
        validatePortfolioTransaction: {
          isUnavailable,
          isOutsideOperationTime,
          isEditadaBox,
          message,
          schedulingSuccessMessage
        }
      }: ISuggestedPortfolioTransaction = await validatePortfolioTransaction(
        this.portfolio,
        this.valueToInvest
      );

      if (isUnavailable || isOutsideOperationTime || isEditadaBox) {
        if (isOutsideOperationTime && isImpersonate()) {
          this.scheduledInvestment();
          return;
        }

        this.showSuggestedTransactionModal = true;
        if (isUnavailable) {
          this.modalErrorType = modalErrorTypes.Unavailable;
          trackErrorModalUnavailable();
        } else if (isOutsideOperationTime) {
          this.modalErrorType = modalErrorTypes.Scheduled;
          this.scheduleMessage = message || '';
          WarrenSession.set(
            StorageKeys.ScheduleInvestmentMessage,
            schedulingSuccessMessage || ''
          );
          trackErrorModalScheduled();
        } else if (isEditadaBox) {
          this.modalErrorType = modalErrorTypes.Editadabox;
          trackErrorModalEditadabox();
        }
        this.loading = false;
        return false;
      }
    } catch (e) {
      trackErrorTransaction();
      this.isValidationTransactionFetchFailed = true;
      this.loading = false;
      return false;
    }
    this.resetInputErrors();
    return true;
  }

  private get isVirtual() {
    return this.context.portfolioInformation?.isVirtual || false;
  }

  private get isButtonDisabled() {
    return (
      this.valueToInvest === 0 ||
      this.context.isCreatingTransaction ||
      this.loading
    );
  }

  public get hasErrorContent() {
    return this.context.isAccountDataFetchFailed || this.hasError;
  }

  private get walletDetails() {
    return {
      name: this.context.portfolioName,
      totalGrossWorth:
        this.context.portfolioInformation?.totalGrossWorth?.formatted ||
        'R$ 0,00',
      minimumDeposit: {
        minimumValueToDeposit:
          this.context.portfolioInformation?.minimumValueToDeposit
            ?.formatted! || 'R$ 0,00',
        monthlyInvestment:
          this.context.portfolioInformation?.monthlyInvestment?.formatted ||
          'R$ 0,00'
      }
    };
  }

  private get canShowTermsAndRisks() {
    return userCanPerform(Operation.ShowTermsAndRisks);
  }

  async fetchPortfolioTerms() {
    try {
      this.hasError = false;
      this.loading = true;

      this.portfolioTerms = await getPortfolioTerm(
        this.portfolio,
        this.isVirtual
      );
    } catch {
      this.hasError = true;
    } finally {
      this.loading = false;
    }
  }

  private redirectToInvestmentSuccess() {
    const query =
      this.isScheduledInvestment && !isImpersonate()
        ? { scheduled: 'true' }
        : {};

    this.$router.push({
      name: 'wealthProductTransactionCompleted',
      query
    });
  }

  async scheduledInvestment() {
    this.isScheduledInvestment = true;
    this.loading = true;
    await this.processTransaction(this.valueToInvest);
    trackInvestmentSchedule();
    this.loading = false;
  }

  private setInputError(errorMessage: string) {
    this.hasInputError = true;
    this.errorMessage = errorMessage;
  }

  private handleInputFocus() {
    this.resetInputErrors();
  }

  private resetInputErrors() {
    this.loading = false;
    this.hasInputError = false;
    this.errorMessage = '';
    this.resetTransactionState();
  }

  private handleNoMoneyInAccount() {
    trackNoMoneyWarning(this.context.portfolioName);
    this.openBankDetailsModal();
  }

  private generateInvoiceTrackEvent() {
    trackGenerateInvoiceEvent(this.context.portfolioName);
  }

  public closeSuggestedTransactionModal() {
    this.showSuggestedTransactionModal = false;
    trackErrorClickGoBack();
  }

  @Watch('context.isCreateTransactionSucceeded')
  private watchCreateTransactionSuccess(success: boolean) {
    if (!success) return;
    trackInvest(
      this.context.portfolioName,
      this.valueToInvest,
      this.context.portfolioInformation?.minimumValueToDeposit?.value || 0
    );
    PortfolioModule.addInvestedPortfolio(this.portfolio);
    this.redirectToInvestmentSuccess();
  }

  @Watch('context.isCreateTransactionFailed')
  private watchCreateTransaction(hasError: boolean) {
    if (!hasError) return;
    this.errorMessage = 'Ops! Aconteceu algum problema, tente novamente';
  }

  @Watch('valueToInvest')
  private watchValue() {
    this.resetInputErrors();
  }
}
