import { object, string, boolean, number } from 'yup';
import { isValid as isValidCPF } from 'cpf';
import { isValidPhone } from '@/modules/common/helpers/phone';
import { isValidDate } from '@/modules/common/helpers/date';
import { isFullName } from '@/modules/common/helpers/name';
import { YupValidation } from '@/modules/common/helpers/yup-validation';
import { isString } from '@/modules/common/helpers/string';
import { isGreaterThanMinimalMovableProperty } from '@/modules/common/helpers/number';

function isValidRne(rne: string) {
  return rne.length === 8;
}

const minDepositValue = ' R$ 100';

const schema = object().shape({
  personalProfile: object().shape({
    fullName: string()
      .test({
        test: isFullName,
        message: 'Seu nome completo deve conter sobrenome.'
      })
      .required('Por favor, insira seu nome completo.'),

    cpf: string()
      .test({
        test: isValidCPF,
        message: 'Informe um CPF válido.'
      })
      .required('CPF é obrigatório.'),

    sex: string()
      .test({
        test: isString,
        message: 'Sexo é obrigatório.'
      })
      .required('Sexo é obrigatório.'),

    birthDate: string()
      .test({
        test: isValidDate,
        message: 'Informe sua data de nascimento válida.'
      })
      .required('Informe sua data de nascimento válida.'),

    maritalStatus: string()
      .test({
        test: isString,
        message: 'Estado civil é obrigatório.'
      })
      .required('Estado civil é obrigatório.'),

    partnerName: string()
      .nullable()
      .when('maritalStatus', {
        is: (maritalStatus: string) =>
          maritalStatus === 'casado-brasileiro-nato' ||
          maritalStatus === 'casado-brasileiro-naturalizado' ||
          maritalStatus === 'casado-estrangeiro' ||
          maritalStatus === 'uniao-estavel',
        then: string()
          .test({
            test: isFullName,
            message: 'Complete com mais de 1 nome.'
          })
          .required('Nome da(o) companheira(o) é obrigatório.'),
        otherwise: string()
      }),

    partnerCpf: string()
      .nullable()
      .when('maritalStatus', {
        is: (maritalStatus: string) =>
          maritalStatus === 'casado-brasileiro-nato' ||
          maritalStatus === 'casado-brasileiro-naturalizado' ||
          maritalStatus === 'uniao-estavel',
        then: string()
          .test({
            test: isValidCPF,
            message: 'Informe um CPF válido.'
          })
          .required('CPF é obrigatório.'),
        otherwise: string()
      }),

    partnerForeignRne: string()
      .nullable()
      .when('maritalStatus', {
        is: (maritalStatus: string) => maritalStatus === 'casado-estrangeiro',
        then: string()
          .test({
            test: isValidRne,
            message: 'Informe um RNE válido'
          })
          .required('RNE é obrigatório.'),
        otherwise: string()
      }),

    motherName: string()
      .test({
        test: isFullName,
        message: 'O nome completo da sua mãe deve conter sobrenome.'
      })
      .required('Por favor, insira o nome da sua mãe completo.'),

    isRegisterStarted: boolean(),

    mobile: string()
      .transform((_currentValue: string, originalValue: string) => {
        return originalValue.substring(3);
      })
      .when('isRegisterStarted', {
        is: true,
        then: string().required('Celular é obrigatório.'),
        otherwise: string()
          .test({
            test: isValidPhone,
            message: 'Complete os números que faltam.'
          })
          .required('Celular é obrigatório.')
      })
  }),
  investmentProfile: object().shape({
    totalInvested: number(),
    deposit: number()
      .test({
        test: isGreaterThanMinimalMovableProperty,
        message: `O valor aproximado dos seus bens deve ser no mínimo ${minDepositValue}.`
      })
      .required(
        `O valor aproximado dos seus bens deve ser no mínimo ${minDepositValue}.`
      )
  })
});

export default new YupValidation(schema);
