
import { Component, Vue, Prop } from 'vue-property-decorator';

import { BaseIcon, NavBar, Tab } from '@warrenbrasil/nebraska-web';
import { MenuItem } from './types';
import { namespace } from 'vuex-class';
import { AccountStates } from '@/modules/common/services/http/account';

const CustomerModule = namespace('CustomerModule');

@Component({
  components: {
    BaseIcon,
    NavBar,
    Tab
  }
})
export default class NavbarMenu extends Vue {
  @Prop({ type: Boolean, default: false })
  readonly isTransparent?: boolean;

  @Prop({ type: Array, required: true })
  readonly menu!: MenuItem[];

  @CustomerModule.Getter('getAccountStatus')
  readonly accountStatus!: AccountStates;

  isMenuItemActive(item: MenuItem) {
    return this.$route.matched.some(matched => matched.name === item.to);
  }

  isMenuItemDisabled(item: MenuItem) {
    if (item.to === 'home') {
      return false;
    } else {
      return !this.accountStatus?.clientEnabled;
    }
  }

  getMenuItemHref(item: MenuItem) {
    return `#/${item.to}`;
  }

  getIcon(item: MenuItem) {
    return this.isMenuItemActive(item)
      ? item.icons.active
      : item.icons.inactive;
  }

  changeTab(item: MenuItem) {
    const values = ['wealth', 'trade'];

    if (values.includes(item.to) && !this.accountStatus?.hasInvestmentProfile) {
      this.$router.push({
        query: {
          ...this.$route.query,
          suitabilityModal: 'true'
        }
      });
    } else {
      this.$emit('tab-select', item.label);
      this.$router.push({ name: item.to });
    }
  }
}
