import { RouteConfig } from 'vue-router';

import Register from '@/modules/onboarding/register/views/Register/Register.vue';
import PersonalData from '@/modules/onboarding/register/views/PersonalData/PersonalData.vue';
import Address from '@/modules/onboarding/register/views/Address/Address.vue';
import NationalityOccupation from '@/modules/onboarding/register/views/NationalityOccupation/NationalityOccupation.vue';
import Documents from '@/modules/onboarding/register/views/Documents/Documents.vue';
import DocumentsUpload from '@/modules/onboarding/register/views/DocumentsUpload/DocumentsUpload.vue';

import Suitability from '@/modules/onboarding/suitability/views/Suitability/Suitability.vue';
import SuitabilityProfile from '@/modules/onboarding/suitability-profile/views/SuitabilityProfile/SuitabilityProfile.vue';
import FaceMatchView from '@/modules/authentication/views/FaceMatch/FaceMatchView.vue';
import SignUp from '@/modules/onboarding/signup/views/SignUp/SignUp.vue';
import redirectTo, { RedirectList } from '../helpers/redirect-to';
import sduiSuitabilityGuard from '../helpers/sdui-suitability-guard';
import { logOffUser } from '@/modules/common/routes/navigation-guards/logOffUser';

export const onboardingRoutes: RouteConfig[] = [
  {
    name: 'register',
    path: '/register',
    component: Register,
    meta: {
      context: 'onboarding',
      requiresAuth: false
    },
    redirect: () => '/register/personal',
    children: [
      {
        name: 'personal',
        path: 'personal',
        component: PersonalData
      },
      {
        name: 'address',
        path: 'address',
        component: Address
      },
      {
        name: 'nationality',
        path: 'nationality',
        component: NationalityOccupation
      },
      {
        name: 'documents',
        path: 'documents',
        component: Documents
      },
      {
        name: 'uploads',
        path: 'uploads',
        component: DocumentsUpload
      }
    ]
  },
  {
    name: 'faceMatchIdentification',
    path: '/identification',
    component: FaceMatchView,
    meta: {
      context: 'onboarding',
      requiresAuth: true
    }
  },
  {
    name: 'signup',
    path: '/signup',
    beforeEnter: logOffUser,
    component: SignUp,
    meta: {
      context: 'onboarding',
      requiresAuth: false
    }
  },
  {
    name: 'suitability',
    path: '/suitability',
    beforeEnter: sduiSuitabilityGuard,
    component: Suitability,
    meta: {
      context: 'onboarding',
      requiresAuth: true
    }
  },
  {
    name: 'suitabilityWhitelabel',
    path: '/suitability/:partner/:referralId',
    component: Suitability,
    meta: {
      context: 'onboarding',
      requiresAuth: false
    }
  },
  {
    name: 'myprofile',
    path: '/suitability/my-profile',
    component: SuitabilityProfile,
    meta: {
      context: 'onboarding',
      requiresAuth: false
    }
  },
  {
    name: 'customer-identification',
    path: '/customer-identification',
    component: () => redirectTo(RedirectList.CustomerIdentification),
    meta: {
      context: 'onboarding',
      requiresAuth: true
    }
  }
];
