
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  Row,
  ToggleSwitch,
  BaseIcon,
  RoundedIcon,
  Tag,
  BaseText,
  ChipStatus,
  ButtonSecondary,
  ButtonContainer,
  CopyButton
} from '@warrenbrasil/nebraska-web';
import { SideSheet } from '@/modules/common/components/SideSheet';
import { NavBar } from '@/modules/common/components/__deprecated__/NavBar';
import {
  NavigationPanelWrapper,
  NavigationPanel
} from '@/modules/common/components/NavigationPanel';
import { NotificationList } from './components/NotificationList';
import { Notification } from '@/modules/common/components/Notification';
import { FullHeightPanel } from '@/modules/common/components/FullHeightPanel';
import { CountBadge } from '@/modules/common/components/badges/CountBadge';
import { CloseButton } from '@/modules/common/components/buttons';
import { BackButton } from '@/modules/common/components/buttons/BackButton';
import { MenuItem } from './components/MenuItem';
import { DeleteAccount } from './components/DeleteAccount';
import { SwitchAccountModal } from './components/SwitchAccountModal';
import { getString } from '@/modules/common/helpers/resources';
import { ICustomer } from '@/types/models/Customer';
import { RouteMixin } from '@/modules/common/mixins/route';
import {
  IUserB2B,
  getUserB2B,
  StorageKeys
} from '@/modules/common/services/local-storage';
import {
  logoutImpersonate,
  isWarrenManaged
} from '@/modules/common/services/partner';
import { IAdvisorProfile } from '@/types/models';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { FeatureFlagsModule } from '@/modules/common/store/FeatureFlagsModule';
import { NotificationsModule } from '@/store/modules/NotificationsModule';
import { FeatureFlags } from '@/types/models/FeatureFlags';
import { GtmTracker } from '@/modules/common/services/analytics';
import { useDashStore } from '@/modules/common/store/DashModule';
import * as trackAmplitudeEvents from './track-events';
import { StatementType } from '@/modules/investments/types/statementType';
import { IMyAccountMenu, MyAccountAction } from './types';
import { MFAModules } from '@/modules/authentication/views/SecurityView/components/MultiFactorAuthentication';
import { RegulatoryUpdateStatus } from '../../services/http/account';
import { getMyAccountMenu } from './services/my-account';

const customerModule = namespace('CustomerModule');
const UIModule = namespace('UIModule');

@Component({
  components: {
    BaseText,
    BaseIcon,
    RoundedIcon,
    Row,
    CountBadge,
    SwitchAccountModal,
    Notification,
    MenuItem,
    SideSheet,
    NavigationPanelWrapper,
    NavigationPanel,
    FullHeightPanel,
    DeleteAccount,
    NotificationList,
    BackButton,
    CloseButton,
    NavBar,
    ToggleSwitch,
    Tag,
    ChipStatus,
    ButtonSecondary,
    ButtonContainer,
    CopyButton
  }
})
export default class MenuList extends Mixins(RouteMixin) {
  private readonly dashModule = useDashStore();

  @Prop({ type: Number, default: 0 })
  public countNotification!: number;

  @customerModule.Getter('getCustomerId')
  private customerId!: string;

  @customerModule.State('regulatoryUpdateStatus')
  private regulatoryUpdateStatus!: RegulatoryUpdateStatus;

  private getString = getString;
  private NebraskaColors = NebraskaColors;
  private deleteAccountModal = false;
  private userB2b = getUserB2B()!;
  readonly trackAmplitudeEvents = trackAmplitudeEvents;
  public myAccountMenu: IMyAccountMenu = {
    title: '',
    rows: [],
    button: { label: '', action: MyAccountAction.DeleteAccount }
  };

  private showPositions = false;

  @customerModule.Getter('getCustomer')
  private customer!: ICustomer;

  @UIModule.Getter('getIsOpenLeftSideBar')
  private isOpen!: boolean;

  @UIModule.Mutation('closeLeftSideBar')
  private close!: () => void;

  @Watch('isOpen')
  private watchMenuOpening(isOpen: boolean) {
    isOpen && trackAmplitudeEvents.trackMenuOpening();
  }

  private get unreadNotificationsCount() {
    return NotificationsModule.getUnreadNotificationsCount;
  }

  private get unreadNotifications() {
    return NotificationsModule.getUnreadNotifications;
  }

  private get hasMFAFlag() {
    return FeatureFlagsModule.isEnabled(FeatureFlags.MFA_ENABLE_PROCESS);
  }

  private get hasFixedIncomeReferenceValue() {
    return FeatureFlagsModule.isEnabled(
      FeatureFlags.FIXED_INCOME_REFERENCE_VALUE
    );
  }

  private get hasTradeNoteFlag() {
    return FeatureFlagsModule.isEnabled(FeatureFlags.TRADE_3_0);
  }

  private get showPensionMenu() {
    return (
      this.customer && this.customer.isRegisterVerified && isWarrenManaged()
    );
  }

  private get showFAQPage() {
    return FeatureFlagsModule.isEnabled(FeatureFlags.CS_CHAT);
  }

  private get showStatementDownload() {
    return FeatureFlagsModule.isEnabled(FeatureFlags.STATEMENT_DOWNLOAD_B2C);
  }

  get showFixedIncomeStatementDownload() {
    return this.hasFixedIncomeReferenceValue;
  }

  readonly StatementType = StatementType;

  private async created() {
    this.loadMenuList();
    this.myAccountMenu = await getMyAccountMenu(this.customerId);
  }

  private get trackEvent() {
    return GtmTracker.trackerWithDefaultProps({
      label: 'geral',
      category: 'menu_sidebar'
    });
  }

  public suitabilityLink() {
    this.goToPath('/app/#/suitability/my-profile?suitability');
  }

  private get complianceTermsLink() {
    return process.env.VUE_APP_COMPLIANCE_TERMS;
  }

  public handleMyAccountActions(action: MyAccountAction) {
    const goToChangeEmail = () => {
      this.goToRouteName(
        'user-email',
        trackAmplitudeEvents.trackChangeEmailSubmenu
      );
    };

    const goToInvestmentProfile = () => {
      this.suitabilityLink();
    };

    const goToChangePassword = () => {
      this.goToRouteName(
        'user-password',
        trackAmplitudeEvents.trackChangePasswordSubmenu
      );
    };

    const goToEditRegister = () => {
      this.goToRouteName(
        'user-account',
        trackAmplitudeEvents.trackAccountRegisterSubmenu
      );
    };

    const goToInitialRegister = () => {
      this.goToPath('/app/#/register/personal');
    };

    const goToRegulatoryUpdate = () => {
      this.goToRouteName('regulatory-update');
    };

    const goToComplianceTerms = () => {
      trackAmplitudeEvents.trackViewTermsSubmenu();
      window.open(this.complianceTermsLink, '_blank');
    };

    const goToMfaValidationThenChangeEmail = () => {
      this.$router.push({
        name: 'security',
        query: { module: MFAModules.MENU_CHANGE_EMAIL }
      });
    };

    const goToMfaValidationThenEditRegister = () => {
      this.$router.push({
        name: 'security',
        query: { module: MFAModules.MENU_EDIT_REGISTER }
      });
    };

    const goToDeleteAccount = () => {
      this.deleteAccountModal = true;
      trackAmplitudeEvents.trackDeleteAccountSubmenu();
    };

    const actions = {
      [MyAccountAction.ChangeEmail]: goToChangeEmail,
      [MyAccountAction.ChangePassword]: goToChangePassword,
      [MyAccountAction.DeleteAccount]: goToDeleteAccount,
      [MyAccountAction.EditRegister]: goToEditRegister,
      [MyAccountAction.InitialRegister]: goToInitialRegister,
      [MyAccountAction.InvestmentProfile]: goToInvestmentProfile,
      [MyAccountAction.MfaValidationThenChangeEmail]:
        goToMfaValidationThenChangeEmail,
      [MyAccountAction.MfaValidationThenEditRegister]:
        goToMfaValidationThenEditRegister,
      [MyAccountAction.RegulatoryUpdate]: goToRegulatoryUpdate,
      [MyAccountAction.Terms]: goToComplianceTerms
    };

    actions[action]();
  }

  private goToProducts() {
    window.open('https://warren.com.br/app/#/products', '_blank');
  }

  private goToAllocationPage() {
    trackAmplitudeEvents.trackAllocationClick();
    window.open('https://lp.warren.com.br/warren-asset-allocation', '_blank');
  }

  private goToAnalysisPage() {
    trackAmplitudeEvents.trackAnalysisClick();
    window.open('https://lp.warren.com.br/entenda-sobre-o-mercado', '_blank');
  }

  private goToAssetPage() {
    trackAmplitudeEvents.trackAssetsManagementClick();
    window.open('https://lp.warren.com.br/conheca-nossos-fundos', '_blank');
  }

  private goToHelpCenter() {
    this.trackEvent({ action: 'click_me_ajuda' });
    this.goToRouteName('help-center');
  }

  protected goToPension() {
    this.trackEvent({ action: 'click_previdencia' });
    window.open('https://lp.warren.com.br/previdencia', '_blank');
  }

  private goToRouteName(name: string, tracker?: Function) {
    this.$router.push({ name });
    this.close();
    tracker?.();
  }

  private switchDarkMode() {
    this.dashModule.toggleDarkMode();
  }

  private get isDarkMode() {
    return this.dashModule.isDarkMode;
  }

  private openSwitchAccountModal() {
    (this.$refs as { switchAccountModal: any }).switchAccountModal.open();
  }

  // -- DADOS COMPUTADOS -- //
  private get computedClasses() {
    return ['wrn-cta-white', 'navbar-cta', 'wrn-p-x-md-1'];
  }

  private get computedColor() {
    return ['wrn-navbar-text navbar-text text-p6-semibold'];
  }

  private loadMenuList() {
    return NotificationsModule.fetchNotifications();
  }

  private get computedProfileType(): string {
    if (
      this.customer &&
      this.customer.investmentProfile &&
      this.customer.investmentProfile.computedProfileType
    ) {
      return this.customer.investmentProfile.computedProfileType;
    } else {
      return '';
    }
  }

  private isCustomerPJ() {
    if (!this.customer) return;
    return !!this.customer.professionalProfile;
  }

  // -- ME AJUDA -- //
  private openZendeskChat() {
    window.openChat();
  }

  // -- LOGOUT -- //
  private decideLogout() {
    if (
      this.customer &&
      this.customer.clientType &&
      this.customer.clientType.whitelabel
    ) {
      if (this.customer.isImpersonate) {
        logoutImpersonate();
      } else {
        this.$router.push({
          name: 'signout',
          query: {
            redirectToPath: '/app/#/signin',
            preserveTokens: [
              StorageKeys.HasShowedInvestmentViewTutorial,
              StorageKeys.HasUsedPositionDetailsButton,
              StorageKeys.DarkMode,
              StorageKeys.FeatureSurvey,
              StorageKeys.PartnerDashNewsInformation,
              StorageKeys.PartnerPortfolioNewsInformation,
              StorageKeys.PartnerStatementNewsInformation,
              StorageKeys.PartnerProfileNewsInformation
            ]
          }
        });
      }
    } else {
      this.$router.push({
        name: 'signout',
        query: {
          preserveTokens: [
            StorageKeys.HasShowedInvestmentViewTutorial,
            StorageKeys.HasUsedPositionDetailsButton,
            StorageKeys.DarkMode,
            StorageKeys.FeatureSurvey,
            StorageKeys.PartnerDashNewsInformation,
            StorageKeys.PartnerPortfolioNewsInformation,
            StorageKeys.PartnerStatementNewsInformation,
            StorageKeys.PartnerProfileNewsInformation
          ]
        }
      });
    }
    if (this.isOpen) this.close();
    trackAmplitudeEvents.trackLogoutClick();
  }

  private isAVerifiedPartner(user: IUserB2B) {
    return (
      user &&
      user.clientType &&
      user.clientType.b2bPartner &&
      user.isPartnerRegisterVerified
    );
  }

  private hasABrand(user: IUserB2B) {
    return user && user.brand && user.brand.name;
  }

  private hasAPartnerType(user: IUserB2B) {
    return (
      user &&
      user.advisorProfile &&
      (user.advisorProfile as IAdvisorProfile).type
    );
  }

  private hasCreateInvestorAccountFeature(user: IUserB2B) {
    return (
      user &&
      user.features &&
      user.features.includes('PARTNER_CREATE_INVESTOR_ACCOUNT')
    );
  }

  private isInvestorAccount() {
    return this.userB2b.isSuitabilityFinished;
  }

  private hasSwitchAccount() {
    if (
      this.userB2b &&
      this.isAVerifiedPartner(this.userB2b) &&
      this.hasABrand(this.userB2b) &&
      this.hasAPartnerType(this.userB2b) &&
      this.isInvestorAccount() &&
      this.hasCreateInvestorAccountFeature(this.userB2b)
    ) {
      return true;
    } else {
      return false;
    }
  }

  private goToCosts() {
    this.$router.push({ name: 'costs' });
    trackAmplitudeEvents.trackCostsClick();
    this.close();
  }

  private goToStatementDownload(statementType: StatementType) {
    this.$router.push({
      path: `investments/statement-download/${statementType}`
    });
    trackAmplitudeEvents.trackStatementClick(statementType);
    this.close();
  }

  private goToInvestmentPositionsPage() {
    this.goToRouteName('investmentsPositions');
    trackAmplitudeEvents.trackPositionsClick();
    this.close();
  }

  private goToTradeNotesPage() {
    const currentModule = this.$route.name;
    this.$router.push({
      name: 'tradingNotes',
      query: { module: currentModule }
    });
    trackAmplitudeEvents.trackTradeNotesClick();
    this.close();
  }

  private goToIncomeReportView() {
    trackAmplitudeEvents.trackStatementClick(StatementType.IncomeStatement);

    this.goToRouteName('incomeReport');
  }

  private goToMFAPage() {
    trackAmplitudeEvents.trackMFAClick();
    this.$router.push({ name: 'security' });
  }

  onDeleteAccountClick() {
    this.deleteAccountModal = true;
    trackAmplitudeEvents.trackDeleteAccountSubmenu();
  }

  private get canShowCostsItem() {
    if (this.customer && this.customer.isRegisterVerified) return true;
    else return false;
  }

  private get appVersion() {
    const version = process.env.VUE_APP_WEB_PLATFORM_VERSION;
    return version ? `v${version}` : '';
  }

  public get isCustomerRegulatoryUpdateUpToDate() {
    return (
      this.regulatoryUpdateStatus !== RegulatoryUpdateStatus.BLOCKED &&
      this.regulatoryUpdateStatus !== RegulatoryUpdateStatus.ABOUT_TO_BE_BLOCKED
    );
  }
}
